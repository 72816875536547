import axios, { AxiosRequestConfig } from 'axios';

const instance =  axios.create({
    baseURL: ``
});

const Get = <T>(url: string, options?: AxiosRequestConfig) => {
    return instance.get<T>(url, options)
};

const Post = <T, U>(url: string, body: T, options?: AxiosRequestConfig) => {
    return instance.post<U>(url, body, options);
}

export const http = {
    Get,
    Post
}