import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { navigate } from 'gatsby'
import { formateDate } from "../../utlis/formatDate";
import { FETCH_STATUS } from "../types";
import { FluidObject } from 'gatsby-image';

export type SearchResult = {
    author: string
    category: string
    excerpt: string
    objectID: string
    slug: string
    tags: string[]
    thumbnail: string
    title: string
    avatar: string
    date: string
    description: string
    options: string
}


type SerachState = {
    status: keyof typeof FETCH_STATUS
    query: string | null,
    results: SearchResult[],
    tags: string[]
}

const initialState: SerachState = {
    status: FETCH_STATUS.NONE,
    query: ``,
    results: [],
    tags: []
}

// export const searchArticlesThunk = createAsyncThunk('search/findArticlesThunk', async(query: string) => {
//     if (typeof window !== "undefined" && window.gtag) {
//         const title = `検索結果`
//         const url = `/search?query=${query}`
//         window.gtag('config', 'G-W1X1QPRMHP'  , { 'page_title': title, 'page_location': url, 'page_path': url});
//         window.gtag('config', 'UA-186129670-2', { 'page_title': title, 'page_location': url, 'page_path': url});
//     }
//     const res = await ArticleSearchService.findArticles<SearchResult>(query)
//     return res
// })

const slice = createSlice({
    name: `search`,
    initialState,
    reducers: {
        setQuery: (state, action: PayloadAction<string>) => {
            state.query = action.payload
        },
        addQuery: (state, action: PayloadAction<string>) => {
            if(!state.query) {
                state.query = action.payload
            } else {
                state.query = state.query + action.payload
            }
        },
        setTags: (state, action: PayloadAction<string[]>) => {
            state.tags = action.payload
        }
    },
    // extraReducers: (builder) => {
    //     builder.addCase(searchArticlesThunk.pending, (state) => {
    //         state.status = FETCH_STATUS.FETCHING
    //     })
    //     builder.addCase(searchArticlesThunk.fulfilled, (state, actions) => {
    //         state.status = FETCH_STATUS.FETCHED
    //         state.query = actions.payload.query
    //         state.results = actions.payload.hits
    //         navigate('/search', {
    //             state: state.query
    //         })
    //     })
    // }
})
const DISPLAY_TAG_NUM = 30;
export const { reducer: searchReducer } = slice
export const {
    setQuery,
    addQuery,
    setTags
} = slice.actions
export const selectQuery = createSelector((state: RootState) => state.searchState, (state) => state.query);
export const selectSearchResults = createSelector((state: RootState) => state.searchState, (state) => {
    return state.results.map((r) => ({...r, date: formateDate(r.date)}))
});
export const selectSearchResultsCount = createSelector((state: RootState) => state.searchState, (state) => state.results.length);
export const selectSearchFetchStatus = createSelector((state: RootState) => state.searchState, (state) => state.status);
export const selectDisplaySearchTags = createSelector((state: RootState) => state.searchState, (state) => state.tags.slice(0, DISPLAY_TAG_NUM));
