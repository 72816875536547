import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { addIds, articleReducer } from './articles';
import { searchReducer, setQuery } from './search';
import { FETCH_STATUS } from './types';

const rootReducer = combineReducers({
    articleState: articleReducer,
    searchState: searchReducer
});

/**
 * gatsbyのbuildが通らなかったため無理やり解決しています。
 * 触らないでください。
 * いい方法を思いついたら教えてください。
 * by keshiwo
 */
let localStorage
let JSON
const myStrage = {
    getItem: (key: string): string => '',
    setItem: (key: string, value: string): void => {},
    removeItem: (key: string): void => {}
}
const myParser = {
    parse:(text: string, reviver?: (this: any, key: string, value: any) => any): any => {},
    stringify: (value: any, replacer?: (this: any, key: string, value: any) => any, space?: string | number): string => ''
}

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({ reducer: rootReducer });

export const LikeKey = 'nextmagazine_like_key';
export const SearchKey = `ownedmedia_search_key`


const setItem = (key: string, value: string) => {
    (localStorage as any || myStrage).setItem(key, value)
}
const getItem = (key) => {
    return (localStorage as any || myStrage).getItem(key)
}
const removeItem = (key: string) => {
    return (localStorage as any || myStrage).removeItem(key)
}

store.subscribe(() => {
    try{
        setItem(LikeKey, JSON.stringify(store.getState().articleState.ids));
        const { query, status } = store.getState().searchState
        const pathname = typeof window !== `undefined` ? window.location.pathname : ''
        // localStorageから検索文字列が破棄されるタイミングがなかったため実装
        // 検索結果ページでは、リロード後も検索結果を維持するため、破棄しない
        if(pathname !== '/search/' && status === FETCH_STATUS.NONE) {
            removeItem(SearchKey);
        }
        else if(query) {
            setItem(SearchKey, query);
        }
    }catch(e) {
        localStorage = window.localStorage;
        JSON = window.JSON;
        store.dispatch(addIds({ids: (JSON as any||myParser).parse(getItem(LikeKey) || "[]")}))
        store.dispatch(setQuery(getItem(SearchKey)))
    }
});

export { store };
