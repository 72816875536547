import React, { useState, createContext, useContext } from "react"

/**
 * お仕事のご依頼のお問い合わせ
 */
type jobsFormValues = {
  inqueryType: string
  purposeRequest: string
  content: string
  company: string
  name: string
  email: string
  prefecture: string
  webSite: string
  file: HTMLInputElement
}
type jobsFormState = {
  state: jobsFormValues
  setState: React.Dispatch<React.SetStateAction<jobsFormValues>>
}
const jobsInitState: jobsFormValues = {
  inqueryType: "",
  purposeRequest: "",
  content: "",
  company: "",
  name: "",
  email: "",
  prefecture: "",
  webSite: "",
  file: null,
}
const jobsFormValuesContext = createContext<jobsFormState>({
  state: jobsInitState,
  setState: () => {},
})

/**
 * パートナーシップのお問い合わせ
 */
type partnershipFormValues = {
  inqueryType: string
  content: string
  company: string
  name: string
  email: string
  prefecture: string
  webSite: string
  file: HTMLInputElement
}
type partnershipFormState = {
  state: partnershipFormValues
  setState: React.Dispatch<React.SetStateAction<partnershipFormValues>>
}
const partnershipInitState: partnershipFormValues = {
  inqueryType: "",
  content: "",
  company: "",
  name: "",
  email: "",
  prefecture: "",
  webSite: "",
  file: null,
}
const partnershipFormValuesContext = createContext<partnershipFormState>({
  state: partnershipInitState,
  setState: () => {},
})

/**
 * サービスに関するお問い合わせ
 */
export type servicesFormValues = {
  types: Array<string>
  content: string
  company: string
  name: string
  email: string
  webSites: Array<string>
  files: Array<HTMLInputElement>
}
type servicesFormState = {
  state: servicesFormValues
  setState: React.Dispatch<React.SetStateAction<servicesFormValues>>
}
const servicesInitState: servicesFormValues = {
  types: [],
  content: "",
  company: "",
  name: "",
  email: "",
  webSites: [],
  files: [],
}
const servicesFormValuesContext = createContext<servicesFormState>({
  state: servicesInitState,
  setState: () => {},
})

/**
 * 採用エントリー
 */
export type recruitFormValues = {
  name: string
  email: string
  resumeFiles: Array<HTMLInputElement>
  workFiles: Array<HTMLInputElement>
  webSites: Array<string>
  point: string
  other: string

}
type recruitsFormState = {
  state: recruitFormValues
  setState: React.Dispatch<React.SetStateAction<recruitFormValues>>
}
const recruitsInitState: recruitFormValues = {
  name: "",
  email: "",
  resumeFiles: [],
  workFiles: [],
  webSites: [],
  point: "",
  other: "",
}
const recruitsFormValuesContext = createContext<recruitsFormState>({
  state: recruitsInitState,
  setState: () => {},
})

/**
 * その他のお問い合わせ
 */
export type othersFormValues = {
  types: Array<string>
  content: string
  company: string
  name: string
  email: string
  tel: string
  address: string
  webSites: Array<string>
  files: Array<HTMLInputElement>
}
type othersFormState = {
  state: othersFormValues
  setState: React.Dispatch<React.SetStateAction<othersFormValues>>
}
const othersInitState: othersFormValues = {
  types: [],
  content: "",
  company: "",
  name: "",
  email: "",
  tel: "",
  address: "",
  webSites: [],
  files: [],
}
const othersFormValuesContext = createContext<othersFormState>({
  state: othersInitState,
  setState: () => {},
})

/**
 * カジュアル面談
 */
export type casualVisitFormValues = {
  name: string
  age: string
  email: string
  dateType: string
  date1: string
  dateType1: string
  date2: string
  dateType2: string
  date3: string
  dateType3: string
  methods: string
  ask: string
}
type casualVisitFormState = {
  state: casualVisitFormValues
  setState: React.Dispatch<React.SetStateAction<casualVisitFormValues>>
}
const casualVisitInitState: casualVisitFormValues = {
  name:  "",
  age: "",
  email:  "",
  dateType:  "",
  date1:  "",
  dateType1:  "",
  date2:  "",
  dateType2:  "",
  date3:  "",
  dateType3:  "",
  methods:  "",
  ask:  "",
}
const casualVisitFormValuesContext = createContext<casualVisitFormState>({
  state: casualVisitInitState,
  setState: () => {},
})

const FormProvider = ({ children }) => {
  const [jobsFormValues, setJobsFormValues] = useState(jobsInitState)
  const [partnershipFormValues, setPartnershipFormValues] = useState(
    partnershipInitState
  )
  const [servicesFormValues, setServicesFormValues] = useState(
    servicesInitState
  )
  const [recruitFormValues, setRecruitFormValues] = useState(recruitsInitState)
  const [othersFormValues, setOthersFormValues] = useState(othersInitState)
  const [casualVisitFormValues, setCasualVisitFormValues] = useState(casualVisitInitState)

  return (
    <jobsFormValuesContext.Provider
      value={{
        state: {
          inqueryType: jobsFormValues.inqueryType,
          purposeRequest: jobsFormValues.purposeRequest,
          content: jobsFormValues.content,
          company: jobsFormValues.company,
          name: jobsFormValues.name,
          email: jobsFormValues.email,
          prefecture: jobsFormValues.prefecture,
          webSite: jobsFormValues.webSite,
          file: jobsFormValues.file,
        },
        setState: setJobsFormValues,
      }}
    >
      <servicesFormValuesContext.Provider
        value={{
          state: {
            types: servicesFormValues.types,
            content: servicesFormValues.content,
            company: servicesFormValues.company,
            name: servicesFormValues.name,
            email: servicesFormValues.email,
            webSites: servicesFormValues.webSites,
            files: servicesFormValues.files,
          },
          setState: setServicesFormValues,
        }}
      >
        <recruitsFormValuesContext.Provider
          value={{
            state: {
              name: recruitFormValues.name,
              email: recruitFormValues.email,
              resumeFiles: recruitFormValues.resumeFiles,
              workFiles: recruitFormValues.workFiles,
              webSites: recruitFormValues.webSites,
              point: recruitFormValues.point,
              other: recruitFormValues.other,
            },
            setState: setRecruitFormValues,
          }}
        >
          <othersFormValuesContext.Provider
            value={{
              state: {
                types: othersFormValues.types,
                content: othersFormValues.content,
                company: othersFormValues.company,
                name: othersFormValues.name,
                email: othersFormValues.email,
                tel: othersFormValues.tel,
                address: othersFormValues.address,
                webSites: othersFormValues.webSites,
                files: othersFormValues.files,
              },
              setState: setOthersFormValues,
            }}
          >
            <partnershipFormValuesContext.Provider
              value={{
                state: {
                  inqueryType: partnershipFormValues.inqueryType,
                  content: partnershipFormValues.content,
                  company: partnershipFormValues.company,
                  name: partnershipFormValues.name,
                  email: partnershipFormValues.email,
                  prefecture: partnershipFormValues.prefecture,
                  webSite: partnershipFormValues.webSite,
                  file: partnershipFormValues.file,
                },
                setState: setPartnershipFormValues,
              }}
            >
              <casualVisitFormValuesContext.Provider
                value={{
                  state: {
                    name: casualVisitFormValues.name,
                    age: casualVisitFormValues.age,
                    email: casualVisitFormValues.email,
                    dateType: casualVisitFormValues.dateType,
                    date1: casualVisitFormValues.date1,
                    dateType1: casualVisitFormValues.dateType1,
                    date2: casualVisitFormValues.date2,
                    dateType2: casualVisitFormValues.dateType2,
                    date3: casualVisitFormValues.date3,
                    dateType3: casualVisitFormValues.dateType3,
                    methods: casualVisitFormValues.methods,
                    ask: casualVisitFormValues.ask,
                  },
                  setState: setCasualVisitFormValues,
                }}
              >
                {children}
              </casualVisitFormValuesContext.Provider>
            </partnershipFormValuesContext.Provider>
          </othersFormValuesContext.Provider>
        </recruitsFormValuesContext.Provider>
      </servicesFormValuesContext.Provider>
    </jobsFormValuesContext.Provider>
  )
}
const useJobsFormContext = () => useContext(jobsFormValuesContext)
const usePartnershipFormContext = () => useContext(partnershipFormValuesContext)
const useServicesFormContext = () => useContext(servicesFormValuesContext)
const useRecruitsFormContext = () => useContext(recruitsFormValuesContext)
const useOthersFormContext = () => useContext(othersFormValuesContext)
const useCasualVisitFormContext = () => useContext(casualVisitFormValuesContext)

export {
  FormProvider,
  useJobsFormContext,
  usePartnershipFormContext,
  useServicesFormContext,
  useRecruitsFormContext,
  useOthersFormContext,
  useCasualVisitFormContext,
}
