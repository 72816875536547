import './src/style.css';
import "prismjs/themes/prism-tomorrow.css"
import wrapWithProvider from "./src/wrap-with-provider"
export const wrapRootElement = wrapWithProvider

export const onRouteUpdate = ({ location }) => {
  setTimeout(() => {
    var loading = document.getElementById('loading')
    if (loading === undefined || loading === null) return
    loading.style.display = 'none';

    const video = document.getElementById('first-view-video');
    if (video === undefined || video === null) return
    video.currentTime = 0;
    video.play();

  }, 750)
}
