import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { store } from './store';
import { FormProvider } from "./utlis/FormValues"

export default ({ element }: { element: JSX.Element | JSX.Element[] }) => {
    return (
        <Provider store={store}>
            <FormProvider>
                {element}
            </FormProvider>
        </Provider>
    );
}