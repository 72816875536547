import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { ArticlesService } from '../../api/articles';
import { FETCH_STATUS } from '../types'

export type Article = {
    articleId: string,
    totalLikes: number,
    pageView: number
}

export type Ranking = Omit<Article, `totalLikes`> & {period: string}


type ArticlesState = {
    ids: string[]
    status: keyof typeof FETCH_STATUS
    articles: Article[]
    monthly: Ranking[]
}

const initialState: ArticlesState = {
    ids: [],
    status: FETCH_STATUS.NONE,
    articles: [],
    monthly: []
}

export const fetchArticles = createAsyncThunk(`articlesSlice/fetchArticlesThunk`, async () => {
    const { data } = await ArticlesService.getAllArticles();
    return data.data;
})

export const like = createAsyncThunk(`articleSlice/like`, async(article_id: string) => {
    const { data } = await ArticlesService.incrementLIKECount({ article_id });
    return data.data;
})


// ランキングは前月のもの
const generateMonthlyRankingParams = () => {
    const now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth();
    if(month === 0) {
        month = 12;
        year -= 1
    }
    return {
        year,
        month
    }
}

export const fetchMonthlyRankings = createAsyncThunk(`articles/fetchMonthlyRankings`, async() => {
    const {year, month} = generateMonthlyRankingParams()
    return (await ArticlesService.getRankings(year, month)).data.data;
})

const slice = createSlice({
    name: `articlesSlice`,
    initialState,
    reducers: {
        addIds: (state, action: PayloadAction<{ids: string[]}>) => {
            return {
                ...state,
                ids: action.payload.ids
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchArticles.pending, (state) => {
            state.status = FETCH_STATUS.FETCHING;
        });
        builder.addCase(fetchArticles.fulfilled, (state, action) => {
            state.status = FETCH_STATUS.FETCHED;
            state.articles = action.payload.map((a): Article => ({articleId: a.article_id, totalLikes: a.total_likes, pageView: a.page_view ? a.page_view : 0}));
        });
        builder.addCase(like.fulfilled, (state, action) => {
            state.ids = Array.from(new Set([...state.ids, action.payload.article_id]));
            const _state = state.articles.map((article) => {
                if(action.payload.article_id === article.articleId) {
                    return {...article, totalLikes: action.payload.total_likes}
                }
                return article;
            });
            state.articles = _state;
        });
        builder.addCase(fetchMonthlyRankings.pending, (state) => {
            state.status = FETCH_STATUS.FETCHING;
        });
        builder.addCase(fetchMonthlyRankings.fulfilled, (state, action) => {
            state.status = FETCH_STATUS.FETCHED;
            const _state = action.payload.map((r):Ranking => ({
                articleId: r.article_id,
                pageView: r.page_view,
                period: r.period
            }))
            state.monthly = _state;
        });
    }
});

const NUMBER_ARTICLE = 5;

export const { reducer: articleReducer} = slice;
export const { addIds } = slice.actions;
export const selectArticles = createSelector((state: RootState) => state.articleState, (articleState) => articleState.articles)
export const selectMonthlyRanking = createSelector((state: RootState) => state.articleState, (state) => state.monthly)
export const selectFetchStatus = createSelector((state: RootState) => state.articleState, (articleState) => articleState.status)
export const rankingSelector = createSelector(
    (state: RootState) => {
        return state.articleState.articles
    },
    (articles) => {
        const _articles = articles.concat();
        return _articles.sort((a, b) => a.pageView < b.pageView ? 1 : -1).slice(0, NUMBER_ARTICLE);
    }
)