import { http } from './';

const articleResourceURL = `https://jlotnl4npi.execute-api.ap-northeast-1.amazonaws.com/nextmagazine`;

type Response<T> = {
    statusCode: number
    data: T
}

type Article = {article_id: string, total_likes: number, page_view: number}
type Ranking = Article & {period: string}

const getAllArticles = () => {
    return http.Get<Response<Article[]>>(articleResourceURL);
}

const incrementLIKECount = (body: {article_id: string}) => {
    return http.Post<typeof body, Response<Article>>(`${articleResourceURL}/like`, body);
}

const pageView = (body: {article_id: string}) => {
    return http.Post<typeof body, Pick<Response<null>, 'statusCode'>>(articleResourceURL, body);
}

const getRankings = (year: number, month: number, limit: number = 5) => {
    const path = `rankings/${year}${month ? '/'+('00'+month.toString()).slice(-2) : ``}?limit=${limit}`
    return http.Get<Response<Ranking[]>>(`${articleResourceURL}/${path}`)
}

const incrementMonthlyPageView = (body : {articleId: string}) => {
    return http.Post<typeof body, Response<null>>(`${articleResourceURL}/rankings/monthly`, body)
}

export const ArticlesService = {
    getAllArticles,
    incrementLIKECount,
    pageView,
    getRankings,
    incrementMonthlyPageView
}